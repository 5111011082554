<div class="container-fluid pt-5 ">
  <div class="row justify-content-center justify-content-md-between bg-theme_logoscuro">
    <div class="d-none d-md-flex col-md-6 col-xl-7 ml-md-3 ml-xl-5 text-center align-items-center ">
      <h2 class=" text-light fw-light">
        Benvenuto nel software di gestione aziendale che si adatta alle tue esigenze.
      </h2>
    </div>

    <div class="col-10 col-md-5 mr-md-3 mr-xl-5 col-xl-4 align-self-center ">
      <div class="card border-theme_login bg-theme_menu scheda_login">
        <div class="card-body text-center">
          <br>
          <h4 class="card-title font-weight-light">
            Walley
          </h4>
          <!-- <app-login></app-login> -->
          <router-outlet></router-outlet>
        </div>
      </div>
      <small class="form-text text-light fw-light fst-italic">powered by
        <a target="_blank" href="https://www.wallnet.it" title="Wallnet">wallnet.it</a>
      </small>
    </div>
  </div>
</div>
<div class="container-fluid pt-4 bg-theme_logoscuro">
  <!-- <div class="card-columns bg-secondary ">-->
  <div class="row bg-theme_sfondoindex ml-3 mr-3">
    <!--
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
          <div class="card text-center border-light">
              <div class="card-body d-flex align-items-center justify-content-center">
                  <h4 class="card-title ">
                      <a class="a_clean" tabindex="0" data-toggle="popover" data-trigger="focus"
                         data-container="body" data-placement="bottom"
                         data-content="Vivamus
                      sagittis lacus vel augue laoreet rutrum faucibus.">Fatturazione elettronica</a>
                  </h4>
              </div>
          </div>
      </div>-->

    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3 " *ngFor="let etichetta of listaEtichette">
      <mat-card class="altezza_cards">
        <mat-card-header>
          <mat-card-title>{{etichetta.titolo}}</mat-card-title>
          <mat-card-subtitle>{{etichetta.sotto_titolo}}</mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>
  </div>
</div>
