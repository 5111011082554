<div class="container-xxl ">
  <div class="row text-center justify-content-lg-center ">

      <form class="col col-lg-6" name="insertSalaForm" (ngSubmit)="insertSala()"
            [formGroup]="insertSalaForm">
          <div class="">
              <h4>Inserisci i dati per inserire una sala</h4>
              <mat-form-field class="w-100">
                  <mat-label>Nome</mat-label>
                  <input matInput type="text" minlength="1" maxlength="24" name="nome"
                         formControlName="nome">
                  <mat-hint align="end">Lunghezza campo 24</mat-hint>
              </mat-form-field>
              <mat-form-field class="w-100">
                  <mat-label>Descrizione</mat-label>
                  <textarea matInput placeholder="..." formControlName="descrizione"></textarea>
              </mat-form-field>
              <div class="d-flex justify-content-end">
                  <button class=" btn btn-primary" [disabled]="insertSalaForm.invalid">Aggiungi</button>
              </div>
          </div>
      </form>
      <!-- Force next columns to break to new line at md breakpoint and up -->
      <div class="w-100 d-block"><br></div>
      <div class="col col-lg-6">
          <br>
          <h3 class="h3" align="left">Lista sale</h3>
          <mat-form-field class="w-100">
              <mat-label>Filtro</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Cerca sala per nome" #input>
          </mat-form-field>

          <table mat-table [dataSource]="dataSource" class="w-100  mat-elevation-z8">

              <!-- nome Column -->
              <ng-container matColumnDef="nome">
                  <th mat-header-cell *matHeaderCellDef> Nome</th>
                  <td mat-cell *matCellDef="let element" align="left"> {{element.nome}}</td>
              </ng-container>

              <!-- Descrizione Column -->
              <ng-container matColumnDef="descrizione">
                  <th mat-header-cell *matHeaderCellDef> Descrizione</th>
                  <td mat-cell *matCellDef="let element"> {{element.descrizione}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">Nessun dato trovato "{{input.value}}"</td>
              </tr>
          </table>
      </div>
  </div>
</div>
