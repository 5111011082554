import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConnectServerService } from '../../../../../services/connect-server.service';
import { CommonModule } from '@angular/common';
import { Connect } from '../../../../../classes/connect';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Room } from '../interfaces/room';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { PopupDialogService } from '../../../../../services/popup-dialog.service';

@Component({
  selector: 'app-room',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule,
      MatInputModule, MatTableModule, MatIconModule],
  templateUrl: './room.component.html',
  styleUrl: './room.component.scss'
})
export class RoomComponent {
  insertSalaForm: FormGroup;
  displayedColumns: string[] = ['nome', 'descrizione'];
  dataSource!: MatTableDataSource<Room>;

  constructor(private connectServerService: ConnectServerService,
    private fb: FormBuilder, private popupDialogService: PopupDialogService) {
    this.insertSalaForm = this.fb.group({
      nome: ['', Validators.required],
      descrizione: ['']
    });
  }

  ngOnInit(): void {
    this.listaSale();
  }

  listaSale(): void {
    this.connectServerService.getRequestLaraAsync(Connect.urlServerLaraApi, 'agenda/rooms',
      {}).then(
        (sale: Room[]) => {
          this.dataSource = new MatTableDataSource<Room>(sale);
        }
      );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  insertSala(): void {
    const nomeval = this.insertSalaForm.get('nome')?.value;
    const descrizioneval = this.insertSalaForm.get('descrizione')?.value;
    if (nomeval) {
      this.connectServerService.postRequest(Connect.urlServerLaraApi, 'agenda/addRoom',
        {
          nome: nomeval,
          descrizione: descrizioneval
        }).subscribe(
          (esito) => {
            this.popupDialogService.popUpDialogSuccess(esito);
            this.listaSale();
          }
        );
    }
  }
}
