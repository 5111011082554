import { Component, Inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PopupResponseCallApi } from '../../../../../../interfaces/popup-response-call-api';
import { PopupDialogAfterClose } from '../../../../../../interfaces/popup-dialog-after-close';
import { Connect } from '../../../../../../classes/connect';
import { ObjPopupInfoVal } from '../../../../../../classes/obj-popup-info-val';
import { ObjPopupResponseCallApi } from '../../../../../../classes/obj-popup-response-call-api';
import { Anagraficacliforpro } from '../../../anagrafica/interfaces/anagraficacliforpro';
import { Anagrafica } from '../../../anagrafica/interfaces/anagrafica';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConnectServerService } from '../../../../../../services/connect-server.service';
import { SearchAutocompleteService } from '../../../../../../services/search-autocomplete.service';
import { debounceTime, filter, map, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { AnacliforproSede } from '../../../anagrafica/interfaces/anacliforpro-sede';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CompanyLocation } from '../../../../../../interfaces/company-location';
import { AnacliforproContatti } from '../../../anagrafica/interfaces/anacliforpro-contatti';
import { PopupDialogService } from '../../../../../../services/popup-dialog.service';
import { SharedService } from '../../../../../../services/shared.service';
import { Store } from '@ngrx/store';
import { selectCompanyDefaultValues } from '../../../../../../storage-ngrx/company/company.selectors';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MyDatePickerConfigModule } from '../../../../../../modules/my-date-picker-config/my-date-picker-config.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { SharedLavorazioniModule } from '../../../lavorazioni/modules/shared-lavorazioni/shared-lavorazioni.module';
import { DateAdapter as DateAdapterCore, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { it } from 'date-fns/locale';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CalendarEventInterface } from '../../interfaces/calendar-event-interface';

interface CalendarioTipo {
  id: number;
  descrizione: string;
}

interface Sala {
  id: number;
  nome: string;
  descrizione: string;
}
interface Corso {
  id: number;
  nome: string;
}

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatSelectModule,
    MyDatePickerConfigModule, MatDatepickerModule, MatButtonToggleModule, ReactiveFormsModule,
    MatRadioModule, MatAutocompleteModule, MatInputModule, SharedLavorazioniModule, MatSlideToggleModule
  ],
  providers:
  [
    { provide: MAT_DATE_LOCALE, useValue: it },
    { provide: DateAdapterCore, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  ],
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss'
})
export class EventComponent {
  titoloDialog = 'Aggiungi';
  nuovoEvento = true;
  locale = 'it';
  // datePipe = new DatePipe(this.locale);
  // public toggleCheck = false;
  optionsForm!: UntypedFormGroup;

  saleAll$!: Observable<Sala[]>;
  corsiAll$!: Observable<Corso[]>;
  filteredAnacliforpro$!: Observable<Anagraficacliforpro[]>;
  sediAnacliforpro$!: Observable<AnacliforproSede[]>;
  contattiAnacliforpro$!: Observable<AnacliforproContatti[]>;
  filteredAnagrafica$!: Observable<Anagrafica[]>;
  sediAzienda$!: Observable<CompanyLocation[]>;
  luogoAll$!: Observable<any[]>;
  // utentiAll$: Observable<any[]>;
  arrayUtenti = null;
  arrayCorsisti!: any;
  arrayCalendarioTipo: CalendarioTipo[] = [];
  defaultCalendarioTipo = 'Evento';
  selectSala = false;
  selectCorso = false;
  selectAltro = false;
  str_placeholdertitolo = '';
  idcalendario = 0;
  bool_error = true;
  private subscription: Subscription = new Subscription();

  darkTheme: NgxMaterialTimepickerTheme = {
    /*container: {
        bodyBackgroundColor: '#424242',
        buttonColor: '#fff'
    },
    dial: {
        dialBackgroundColor: '#555',
    },
    clockFace: {
        clockFaceBackgroundColor: '#555',
        clockHandColor: '#9fbd90',
        clockFaceTimeInactiveColor: '#fff'
    }*/
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: number,
    private fb: UntypedFormBuilder,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<EventComponent>,
    private connectServerService: ConnectServerService,
    private searchService: SearchAutocompleteService,
    private popupDialogService: PopupDialogService,
    private store: Store) {
    if (data && data > 0) {
      this.titoloDialog = 'Modifica';
      this.nuovoEvento = false;
      this.idcalendario = data;
    }
  }

  ngOnInit(): void {
    // definisci il form
    this.optionsForm = this.fb.group(this.getObj());
    this.setAllData();
  }

  private async setAllData() {
    this.subscription.add(
      this.store.select(selectCompanyDefaultValues).subscribe((companyDefaults) => {
        if (companyDefaults?.calendar_eventtype) {
          this.defaultCalendarioTipo = companyDefaults.calendar_eventtype;
        }
      })
    );

    // setta i dati
    this.setDatiEvento();
    // logica per le ricerche
    this.searchFieldForm();
    this.sediAzienda$ = this.connectServerService.getRequestLara(Connect.urlServerLaraApi, 'company/listaSediAzienda',
      {});
    // logica per i campi scelta
    this.controllFieldForm();

  }

  ngOnDestroy(): void {
    // Annulla tutte le sottoscrizioni per evitare memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  eventoRicorrente(select: any): void {
    if (select === '0') {
      this.optionsForm.get('data_fine_ricorrenza')?.disable();
    } else {
      this.optionsForm.get('data_fine_ricorrenza')?.enable();
    }
  }

  /**
   * In base all'evento si imposta i campi
   * @param event
   */
  eventoTipo(event: number): void {
    const descrizioneTipo = this.arrayCalendarioTipo.find(x => x.id == event)?.descrizione;
    if (descrizioneTipo === 'Promemoria') {
      this.selectSala = false;
      this.selectCorso = false;
      this.selectAltro = false;
      this.optionsForm.get('rifidsala')?.disable();
      this.optionsForm.get('rifidcorsoformazione')?.disable();
      this.optionsForm.get('titolo')?.enable();
      this.optionsForm.get('oraini')?.disable();
      this.optionsForm.get('orafine')?.disable();
      this.optionsForm.get('corsisti')?.disable();
      this.str_placeholdertitolo = 'Promemoria...';
      this.optionsForm.get('tuttoilgiorno')?.disable();
      if (this.nuovoEvento) {
        // this.toggleCheck = true;
        this.optionsForm.patchValue(
          {
            tuttoilgiorno: 1,
            chi: 2
          }
        );
      }
    } else if (descrizioneTipo === 'Sala') {
      this.saleAll$ = this.connectServerService.getRequestLara(Connect.urlServerLaraApi, 'agenda/rooms',
        {});
      this.optionsForm.get('titolo')?.disable();
      this.optionsForm.get('rifidsala')?.enable();
      this.optionsForm.get('rifidcorsoformazione')?.disable();
      this.selectSala = true;
      this.selectCorso = false;
      this.selectAltro = false;
      this.optionsForm.get('oraini')?.enable();
      this.optionsForm.get('orafine')?.enable();
      this.optionsForm.get('corsisti')?.disable();
      this.optionsForm.get('tuttoilgiorno')?.enable();
      if (this.nuovoEvento) {
        // this.toggleCheck = false;
        this.optionsForm.patchValue(
          {
            tuttoilgiorno: 0,
            chi: 1
          }
        );
      }
    } else if (descrizioneTipo === 'Corso') {
      this.corsiAll$ = this.connectServerService.getRequestLara(Connect.urlServerLaraApi, 'training/coursesCalendarList',
        {});
      this.optionsForm.get('titolo')?.disable();
      this.optionsForm.get('rifidcorsoformazione')?.enable();
      this.optionsForm.get('rifidsala')?.disable();
      this.selectCorso = true;
      this.selectSala = false;
      this.selectAltro = false;
      this.optionsForm.get('oraini')?.enable();
      this.optionsForm.get('orafine')?.enable();
      this.optionsForm.get('corsisti')?.enable();
      this.optionsForm.get('tuttoilgiorno')?.enable();
      if (this.nuovoEvento) {
        // this.toggleCheck = false;
        this.optionsForm.patchValue(
          {
            tuttoilgiorno: 0,
            chi: 0
          }
        );
      }
    } else {
      this.selectAltro = false;
      if (descrizioneTipo === 'Evento') {
        this.str_placeholdertitolo = 'Evento...';
      } else {
        this.str_placeholdertitolo = 'Altro...';
        this.selectAltro = true;
      }
      this.optionsForm.get('rifidsala')?.disable();
      this.optionsForm.get('rifidcorsoformazione')?.disable();
      this.optionsForm.get('titolo')?.enable();
      this.optionsForm.get('corsisti')?.disable();
      this.selectSala = false;
      this.selectCorso = false;
      this.optionsForm.get('oraini')?.enable();
      this.optionsForm.get('orafine')?.enable();

      this.optionsForm.get('tuttoilgiorno')?.enable();
      if (this.nuovoEvento) {
        // this.toggleCheck = false;
        this.optionsForm.patchValue(
          {
            tuttoilgiorno: 0,
            chi: 0
          }
        );
      }

    }
  }

  /**
   * Setta le date se tutto il giorno
   * @param event
   */
  eventoTuttoGiorno(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.optionsForm.get('oraini')?.disable();
      this.optionsForm.get('orafine')?.disable();
      // this.toggleCheck = true;
    } else {
      this.optionsForm.get('oraini')?.enable();
      this.optionsForm.get('orafine')?.enable();
      // this.toggleCheck = false;
    }
  }


  // in base al tipo di default devo fare alcune cose
  setVisualizzazione(rifidtipo = null): void {
    let valore = this.defaultCalendarioTipo;
    if (this.defaultCalendarioTipo.length == 0) {
      valore = this.arrayCalendarioTipo[0].descrizione;
    } else {
      if (rifidtipo) {
        valore = rifidtipo;
      }
    }

    // id di promemoria, evento o sala
    const id = this.arrayCalendarioTipo.find(x => x.descrizione == valore)?.id;
    this.optionsForm.patchValue({ rifidtipo: id });
    if (id) {
      this.eventoTipo(id);
    }

  }

  /**
   * La funzione setta i dati sia all'aggiunta che alla modifica
   */
  private async setDatiEvento() {
    await this.connectServerService.getRequestLara(Connect.urlServerLaraApi, 'agenda/typeCalendar', {
    }).toPromise().
      then((arrayCalendarioTipo: CalendarioTipo[]) => {
        // console.log(arrayCalendarioTipo);
        if (arrayCalendarioTipo.length > 0) {
          // console.log(arrayCalendarioTipo);
          this.bool_error = false;
          this.arrayCalendarioTipo = arrayCalendarioTipo;
          this.setVisualizzazione();
        }


      });

    this.getUsersArray();

    if (!this.nuovoEvento) {
      await this.connectServerService.getRequestLara(Connect.urlServerLaraApi, 'agenda/infoEventCalendar',
        {
          id: this.idcalendario
        }).toPromise().
        then((eventoCalendario: CalendarEventInterface[]) => {
          // console.log('eventoCalendario: ', eventoCalendario);
          if (eventoCalendario.length > 0) {
            const arrayIdUtente: any[] = [];
            eventoCalendario[0].utenti.forEach(
              (utente) => {
                arrayIdUtente.push(utente.id);
              }
            );


            const obj = { ...eventoCalendario[0] };
            this.eventoTipo(obj.rifidtipo);
            if (this.selectCorso) {
              const arrayIdCorsisti: any[] = [];
              if (eventoCalendario && eventoCalendario[0].corsisti) {
                eventoCalendario[0].corsisti.forEach(
                  (corsista) => {
                    arrayIdCorsisti.push(corsista.id);
                  }
                );
              }

              obj.corsisti = arrayIdCorsisti;
            }
            if (obj.anagrafica_cliforprodati && typeof obj.anagrafica_cliforprodati == 'object') {
              const obj_anacliforpro: Anagraficacliforpro = obj.anagrafica_cliforprodati;
              this.logicaSceltaAnaCliForPro(obj_anacliforpro);
            }
            obj.utenti = arrayIdUtente;

            if (obj.oraini) {
              obj.oraini = obj.oraini.substring(0, 5);
            }
            if (obj.orafine) {
              obj.orafine = obj.orafine.substring(0, 5);
            }
            const dataini_temp = this.sharedService.stringToDate(String(obj.dataini));
            const datafine_temp = this.sharedService.stringToDate(String(obj.datafine));
            if (dataini_temp) {
              obj.dataini = dataini_temp;
            }
            if (datafine_temp) {
              obj.datafine = datafine_temp;
            }

            // console.log(obj);
            this.optionsForm.patchValue(obj);
          }
        });
    }

  }

  /**
   * Gestione utenti (scelta e selezione)
   */
  private async getUsersArray() {
    await this.connectServerService.getRequestLara(Connect.urlServerLaraApi, 'user/usersListNoAdmin',
      {}).toPromise().
      then(
        (arrayutenti) => {
          this.arrayUtenti = arrayutenti;
        }
      );
    if (this.nuovoEvento) {
      this.connectServerService.getRequestLara(Connect.urlServerLaraApi, 'user/userInfoLog',
        {}).subscribe(
          (infouser) => {
            this.optionsForm.patchValue(
              {
                utenti: [infouser.id]
              }
            );
          }
        )
    }
  }
  /**
   * Gestione corsisti (scelta e selezione)
   */
  private async getCorsistiArray(idanacliforpro: number) {
    await this.connectServerService.getRequestLara(Connect.urlServerLaraApi, 'auth/listaCorsisti',
      {
        id: idanacliforpro
      }).toPromise().
      then(
        (evento: PopupResponseCallApi) => {
          if (evento && evento.valore && evento.valore.collection) {
            this.arrayCorsisti = evento.valore.collection;
          }
        }
      );
  }

  /**
   * Proprietà per le due ricerche
   */
  private searchFieldForm() {
    const filterAnacliforproControll = this.optionsForm.get('anagrafica_cliforprodati');
    if (filterAnacliforproControll) {
      this.filteredAnacliforpro$ = filterAnacliforproControll.valueChanges
        .pipe(
          startWith(''),
          // tap((val) => console.log('iiii: ' + val)),
          map((value) => {
            // console.log('val: ', value);
            // console.log(typeof value);
            const denominazione = typeof value === 'string' ? value : value?.denominazione;
            return denominazione;
          }
          ),
          // tap((val) => this.optionsForm.patchValue({ contattonome: val })),
          filter((value: string) => value != null ? value.length > 1 : false),
          debounceTime(700),
          tap(() => this.cleanSearch()),
          // map(name => name ? this._filter(name) : this.options.slice())
          // map(name => name ? this._filter(name) : [])
          switchMap((value: string) =>
            this.searchService.getAnaCliForPro('all', 0, value))
        );
    }
    const filterAnagraficaControll = this.optionsForm.get('anagrafica');
    if (filterAnagraficaControll) {
      this.filteredAnagrafica$ = filterAnagraficaControll.valueChanges
        .pipe(
          startWith(''),
          map((value) => {
            const denominazione = typeof value === 'string' ? value : value?.denominazione;
            return denominazione;
          }
          ),
          filter((value: string) => value != null ? value.length > 1 : false),
          debounceTime(700),
          tap(() => this.cleanSearch()),
          switchMap((value: string) =>
            this.searchService.getAnagrafica(0, value))
        );
    }

  }
  displayFnCliForPro(anacliforpro: Anagraficacliforpro): string {
    return anacliforpro && anacliforpro.denominazione ? anacliforpro.denominazione : '';
  }
  displayFnAnagrafica(anagrafica: Anagrafica): string {
    return anagrafica && anagrafica.denominazione ? anagrafica.denominazione : '';
  }

  /**
   * Controlli quando si scelglie esterno, interno o non definito
   */
  private controllFieldForm() {
    this.optionsForm.get('chi')?.valueChanges.subscribe(
      (val: any) => {
        // esterno
        if (val == 0) {
          this.optionsForm.controls['anagrafica_cliforprodati'].enable();
          // interno
        } else {
          this.optionsForm.controls['anagrafica_cliforprodati'].disable();
        }
      }
    );
  }



  private logicaSceltaAnaCliForPro(obj_anagrafica: Anagraficacliforpro) {
    // console.log('ana: ',obj_anagrafica);
    if (obj_anagrafica.id && obj_anagrafica.id > 0) {
      this.sediAnacliforpro$ = this.connectServerService.getRequestLara(Connect.urlServerLaraApi,
        'registry/listLocations', {
        id: obj_anagrafica.id
      });
      if (!this.selectCorso) {
        this.contattiAnacliforpro$ = this.connectServerService.getRequestLara(Connect.urlServerLaraApi,
          'auth/listaContatti', {
          id: obj_anagrafica.id
        });
      } else {
        this.getCorsistiArray(obj_anagrafica.id);
      }


    }
  }
  /**
   * Quando seleziono il cliente, fornitore o prospect
   * @param event
   */
  anagraficaCliForProSelect(event: any): void {
    this.sediAnacliforpro$ = of([]);;
    this.contattiAnacliforpro$ = of([]);;
    if (typeof event.option.value === 'object' && event.option.value !== null) {
      const obj_anagrafica: Anagraficacliforpro = event.option.value;
      this.logicaSceltaAnaCliForPro(obj_anagrafica);
    }

  }

  cleanSearch(): void {
  }

  /*
      private _filter(value: string): AnagraficaContatto[] {
          const filterValue = value.toLowerCase();
          return this.options.filter(option => option.ragionesociale.toLowerCase().indexOf(filterValue) === 0);
      }
  */

  /**
   * Controlla i dati prima di mandarli al server
   * @param obj_evento
   */
  public controlloDatiNewEvento(obj_evento: CalendarEventInterface): boolean {
    const obj_esito = new ObjPopupResponseCallApi();
    obj_esito.titolo = 'Attenzione';
    // esterno
    if (obj_evento.chi == 0) {
      if (typeof obj_evento.anagrafica_cliforprodati != 'object' && obj_evento.anagrafica_cliforprodati !== null) {
        obj_esito.messaggio = 'Azienda non inserita correttamente. Devi selezionare un\'azienda dall\'elenco.';
        this.popupDialogService.popUpDialogSuccess(obj_esito, 'warning');
        return false;
      }
      // interno
    }/* else if (obj_evento.chi == 1) {
          if (typeof obj_evento.anagrafica != 'object' && obj_evento.anagrafica !== null) {
              obj_esito.messaggio = 'Utente non inserito correttamente. Seleziona un utente e riprova.';
              this.connectServerService.popUpDialogSuccess(obj_esito, 'warning');
              return false;
          }
      }*/
    if (obj_evento.dataini && obj_evento.datafine) {
      if (!obj_evento.tuttoilgiorno) {
        if ((obj_evento.oraini && obj_evento.orafine) && (obj_evento.oraini < obj_evento.orafine)) {
          return true;
        } else {
          obj_esito.messaggio = 'Orario non inserito correttamente. Controlla e riprova.';
        }
      } else {
        return true;
      }
    } else {
      obj_esito.messaggio = 'Data incompleta. Impossibile aggiungere l\'evento. Inserisci data inizio e data fine e riprova.';
    }
    this.popupDialogService.popUpDialogSuccess(obj_esito, 'warning');
    return false;
  }

  /**
   * Aggiunge l'evento al DB
   */
  public saveEvento(): void {
    const newEvento: CalendarEventInterface = this.optionsForm.getRawValue() as CalendarEventInterface;
    // console.log(newEvento);
    if (this.optionsForm.valid) {
      if (this.controlloDatiNewEvento(newEvento)) {
        // newEvento.tuttoilgiorno = this.toggleCheck;
        newEvento.dataini = this.sharedService.fromDateToString(newEvento.dataini);
        newEvento.datafine = this.sharedService.fromDateToString(newEvento.datafine);
        this.connectServerService.postRequest(Connect.urlServerLaraApi, 'agenda/addEventCalendar',
          {
            objEvento: newEvento
          })
          .subscribe((esito: any) => {
            this.popupDialogService.popUpDialogSuccess(esito);
            const obj_afterclose: PopupDialogAfterClose = {
              avanti: true,
              button_who: '',
              valori: null
            }
            this.dialogRef.close(obj_afterclose);
          });
      }
    } else {
      this.optionsForm.markAllAsTouched();
      const obj_esito = new ObjPopupResponseCallApi();
      obj_esito.titolo = 'Attenzione';
      obj_esito.messaggio = 'Campi mancanti.';
      this.popupDialogService.popUpDialogSuccess(obj_esito, 'warning');
    }

  }

  /**
   * Modifica evento
   */
  public updateEvento(): void {
    const evento: CalendarEventInterface = this.optionsForm.getRawValue() as CalendarEventInterface;

    if (this.controlloDatiNewEvento(evento)) {
      // evento.tuttoilgiorno = this.toggleCheck;
      evento.dataini = this.sharedService.fromDateToString(evento.dataini);
      evento.datafine = this.sharedService.fromDateToString(evento.datafine);

      // console.log(evento);
      this.connectServerService.postRequest(Connect.urlServerLaraApi, 'agenda/updateEventCalendar',
        {
          objEvento: evento
        })
        .subscribe((esito: any) => {
          this.popupDialogService.popUpDialogSuccess(esito);
          const obj_afterclose: PopupDialogAfterClose = {
            avanti: true,
            button_who: '',
            valori: null
          }
          this.dialogRef.close(obj_afterclose);
        });
    }
  }

  /**
   * Cancella evento
   */
  public deleteEvento(): void {
    const ideventocalendario = this.optionsForm.controls['id'].value;
    const obj_pop = new ObjPopupResponseCallApi();
    obj_pop.titolo = 'Eliminare evento';
    obj_pop.messaggio = 'Vuoi eliminare l\'evento? Una volta eliminato non sarà possibile recuperarlo.';
    obj_pop.dialogResultGo = true;
    const obj_popupinfoval = new ObjPopupInfoVal();
    obj_popupinfoval.pulsanteGo = true;
    obj_popupinfoval.testoPulsanteGo = 'Elimina';
    obj_pop.valore = obj_popupinfoval;
    this.popupDialogService.popUpDialogConfirm(obj_pop, 'warning').afterClosed().subscribe(
      (valore: PopupDialogAfterClose
      ) => {
        if (valore && valore.avanti) {
          this.connectServerService.postRequest(Connect.urlServerLaraApi, 'agenda/deleteEventCalendar',
            {
              id: ideventocalendario
            }).subscribe((esito: PopupResponseCallApi) => {
              this.popupDialogService.popUpDialogSuccess(esito);
              const obj_afterclose: PopupDialogAfterClose = {
                avanti: true,
                button_who: '',
                valori: null
              }
              this.dialogRef.close(obj_afterclose);
            });

        }
      });
  }

  private getObj() {
    return {
      id: [0],
      titolo: ['', Validators.required],
      rifidsala: [0, [Validators.required, Validators.min(1)]],
      rifidcorsoformazione: [0, [Validators.required, Validators.min(1)]],
      descrizione: [''],
      rifidtipo: [],
      chi: [0],
      anagrafica: [''],
      rifidaziendasedi: [0],
      anagrafica_cliforprodati: ['', Validators.required],
      // rifidanacliforprodati: [],
      rifidanacliforprosedi: [0],
      rifidanacliforprocontatti: [0],
      corsisti: [''],
      //rifidanagrafica: [],
      dataini: [new Date(), Validators.required],
      datafine: [new Date(), Validators.required],
      tuttoilgiorno: [0],
      oraini: '',
      orafine: '',
      privato: 0,
      utenti: ['', Validators.required]
      /*
      ricorrente: '0',
      data_fine_ricorrenza: {value: '', disabled: true}*/
    };
  }
}
