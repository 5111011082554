import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ConnectServerService } from '../../../../../../services/connect-server.service';
import { Connect } from '../../../../../../classes/connect';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  obj_infoevento!: any;
  private idcalendarioevento = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: number, private connectServerService: ConnectServerService) {
    this.idcalendarioevento = data;

  }

  ngOnInit(): void {
    if (this.idcalendarioevento > 0) {
      this.connectServerService.getRequestLara(Connect.urlServerLaraApi, 'agenda/infoCardEventCalendar',
      {
        id: this.idcalendarioevento
      }).subscribe((eventoCard) => {
        // console.log(eventoCard);
        if (eventoCard && eventoCard.length > 0) {
          this.obj_infoevento = eventoCard[0];
        }
      });
    }
  }
}
